.body {
  width: 100%; }

.text-area {
  height: 80%;
  width: 100%;
  min-width: 50em;
  min-height: 5em;
  outline-color: #c3c3c3;
  border-color: #d3d3d3; }

md-input-container {
  padding: 0px 10px;
  margin: 18px 0px 0px 8px; }
