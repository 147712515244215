.title {
  height: 40px;
  width: 100%; }

.body {
  padding: 16px;
  width: 100px; }

.date {
  padding: 0px, 16px;
  text-align: center; }

.record-card {
  text-align: center;
  padding: 16px; }

.title {
  padding: 16px;
  color: gray; }

md-input-container {
  padding: 0px 10px;
  margin: 18px 0px 0px 8px; }
