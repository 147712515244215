[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide {
  display: none !important; }

.mre-ng-quill {
  padding: 3em; }
  .mre-ng-quill p {
    margin: 0em;
    padding: 0em; }
  .mre-ng-quill ol {
    margin: 0em; }
  .mre-ng-quill ul {
    margin: 0em; }
  .mre-ng-quill blockquote {
    font-style: italic !important;
    margin-top: 0em;
    margin-bottom: 0em; }

.ql-editor {
  font-size: 16px; }

.ql-size-small {
  font-size: 12px; }

.ql-size-large {
  font-size: 24px !important; }

.ql-size-huge {
  font-size: 34px; }

md-sidenav md-content {
  overflow-x: hidden; }

.lightgrey {
  color: lightgrey; }

[md-color-picker] .md-color-picker-preview {
  margin-right: 0.5em; }

.lightestgrey-bg {
  background-color: #e6e6e6; }

.darkgrey, .mre-ng-quill blockquote {
  color: darkgrey; }

.darkergrey {
  color: rgba(0, 0, 0, 0.54) !important; }

.darkestgrey {
  color: #5e5e5e !important; }

body {
  overflow-y: hidden; }

div:focus {
  outline: none;
  border: 0; }

span:focus {
  outline: none;
  border: 0; }

a:focus {
  outline: none;
  border: 0; }

a:-webkit-any-link {
  text-decoration: none; }

.md-button:not([disabled]):hover {
  background-color: transparent; }

md-icon:focus {
  outline: none;
  border: 0; }

md-card:focus {
  outline: none;
  border: 0; }

md-card *:focus {
  outline: none;
  border: 0; }

md-list:focus {
  outline: none;
  border: 0; }

md-list *:focus {
  outline: none;
  border: 0; }

.grid-full-height {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

a:not([href]) {
  cursor: pointer; }

div:focus {
  outline: none;
  border: 0; }

.md-virtual-repeat-container.md-autocomplete-suggestions-container {
  width: 22em !important; }

md-autocomplete.no-float > md-autocomplete-wrap > md-input-container {
  margin-top: 0px; }
  md-autocomplete.no-float > md-autocomplete-wrap > md-input-container > label {
    -webkit-transform: translate3d(1px, 28px, 0) scale(1) !important;
    transform: translate3d(1px, 28px, 0) scale(1) !important;
    color: #bdbdbd; }
  md-autocomplete.no-float > md-autocomplete-wrap > md-input-container.md-input-has-value > label {
    display: none !important; }

.md-button:not([disabled]):hover {
  background-color: transparent; }

a:-webkit-any-link {
  text-decoration-line: none; }

.break-word {
  display: inline-block;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
  word-break: break-all; }

a:not([href]) {
  cursor: pointer; }

.mre-select-header {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14), 0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto; }
  .mre-select-header input {
    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0; }

.paragraph, md-tooltip {
  font-size: 100% !important;
  line-height: 1.5 !important;
  hyphens: auto !important;
  text-align: justify !important; }
  .paragraph + p, md-tooltip + p {
    text-indent: 1.5em !important; }

md-tooltip {
  height: auto !important;
  max-width: 20em !important;
  white-space: normal !important; }
