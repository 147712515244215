.map-assign-dialog {
  width: 40em; }
  .map-assign-dialog .map-assign-dialog__container {
    width: 40em;
    height: 40em; }

.assign-keyword-map-content {
  height: 55vh; }

.assign-keyword-map {
  min-height: 100%; }
