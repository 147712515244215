.edit-customer-dialog {
  height: auto;
  width: 80vw !important; }

.edit-customer-dialog-component {
  max-height: 80vh !important; }

.assign-customer-keyword-dialog {
  max-height: 90vh !important; }

md-tabs {
  min-height: 60vh !important; }

.import-dialog {
  min-height: 30vh !important; }

.customer-template-dialog {
  max-height: 90vh !important; }

.container {
  width: 100%;
  max-width: 82vw;
  height: 90vh; }

.grid {
  width: 100%;
  height: 93vh; }

.bold {
  font-weight: bolder; }
