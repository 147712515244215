.board-map-box {
  height: 5vh;
  position: absolute;
  top: 0;
  left: 0;
  margin: 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: white; }

.board-map-google-map-box {
  width: 100%;
  height: 100%;
  min-width: 96vw; }

.board-map-stack {
  z-index: 1; }

.board-map-container {
  width: 100% !important;
  height: 100%;
  position: relative; }
