.options:hover {
  background-color: grey; }

.mat-autocomplete-panel {
  min-width: 300px; }

.select-client-form-field {
  width: 96%; }

.select-client-form {
  width: 100%; }

.select_client_autocomplete {
  min-width: 400px !important; }
