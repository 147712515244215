html {
  -webkit-text-size-adjust: none; }

body,
html {
  max-width: 100%; }

#view-container {
  max-width: 100% !important; }

menu {
  padding-left: 0;
  margin: 0; }
