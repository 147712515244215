.disclaimer-variables-dialog {
  min-width: 28em;
  max-width: 28em;
  min-height: 48em;
  max-height: 48em; }
  .disclaimer-variables-dialog md-input-container {
    margin-left: 0.8em;
    margin-bottom: 0em; }

.disclaimer-variables-body {
  max-height: 40vh !important;
  min-height: 59vh !important; }

.disclaimer-variables-list-item {
  transform: background-color 100ms ease-in-out;
  border-radius: 25px;
  padding: 8px;
  cursor: pointer; }
  .disclaimer-variables-list-item:hover {
    background-color: #eeeeee;
    transform: background-color 100ms ease-in-out; }
