.edit-enterprise-form {
  width: 100%; }

.edit-enterprise-card {
  margin: 8px; }

.edit-enterprise-container {
  width: 100%;
  margin: 8px; }

.edit-enterprise-field {
  margin: 0px 8px; }
