md-input-container {
  padding: 0px 10px;
  margin: 18px 0px 0px 8px; }

.delta {
  margin: 18px;
  text-align: center;
  padding: 10px; }

.delta-button {
  margin: 18px; }

.chart-content {
  height: 50vh;
  padding: 16px; }

.chart {
  height: 45vh !important; }
