.bad {
  min-height: 20em;
  max-height: 50em;
  height: auto;
  width: 26em; }
  .bad__boards {
    background-color: white; }

.bad-autocomplete .md-autocomplete-suggestion {
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: normal; }

.bad-autocomplete .md-autocomplete-suggestion:last-child {
  border-bottom-width: 0; }

.bad-autocomplete .item-title,
.bad-autocomplete .item-metadata {
  display: block;
  line-height: 2; }

.board-results-list {
  max-height: 80vh; }

.board-results-list-item {
  width: 100%; }
  .board-results-list-item .mat-list-item-content {
    width: 100%;
    justify-content: space-between; }
