.editCustomerUserContainer {
  width: 100%; }
  .editCustomerUserContainer mat-form-field {
    width: 100%; }
  .editCustomerUserContainer phone-number-input {
    width: 100%; }

.editCustomerUserHeader {
  width: 100%;
  background-color: #f3f3f3;
  padding: 8px; }
