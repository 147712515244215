.toolbar .toolbar__loader {
  height: 0.2em; }

.toolbar-title {
  margin: 4px;
  font-weight: bold; }

svg {
  height: auto; }

.custom-toolbar-mini-fab-button {
  margin: 0px 16px !important; }

.toolbar-mat-spinner {
  margin: 0px 16px;
  width: 36px !important;
  height: 24px !important; }

mat-spinner svg {
  width: 50px !important;
  height: 50px !important; }

.menu-button {
  margin: 8px;
  cursor: pointer; }

.example-spacer {
  flex: 1 1 auto; }

.link-parent {
  position: relative;
  display: inline-block;
  width: 160px;
  height: 50px;
  margin: 8px; }

.link-parent span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background: #009688;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 18px; }

.link-parent span:nth-child(2) {
  color: #009688;
  background: #fff;
  overflow: hidden;
  z-index: 2;
  transition: 0.5s;
  clip-path: polygon(100% 0%, 100% 48%, 100% 100%, 100% 99%, 100% 48%, 100% 0); }

.link-parent span:nth-child(2):hover {
  clip-path: polygon(100% 0%, 100% 48%, 100% 100%, 0 98%, 0 50%, 0 0); }

.link-parent span:nth-child(1):hover ~ span:nth-child(2) {
  clip-path: polygon(100% 0%, 100% 48%, 100% 100%, 0 98%, 0 50%, 0 0); }
