.edit-lending-officer-form-field {
  margin: 0px 8px; }

.edit-lending-officer-mat-icon {
  padding: 8px; }

.mat-form-field-prefix {
  top: 0.6em !important; }

.edit-lending-officer-form-toggle {
  width: 100%;
  margin: auto;
  padding: 10px; }

.select-lending-officer-mage {
  width: 100% !important; }

.lending-officer-mage-header {
  width: 100%; }

.lending-officer-mage-select {
  width: 40vw; }
