.keywords-customer-assign-dialog {
  width: 20em; }

.assign-customer-keyword-content {
  height: 50vh !important; }

.keyword-item {
  width: 100%;
  transition: background-color 100ms ease-in-out;
  padding: 8px;
  border-radius: 25px; }
  .keyword-item:hover {
    background-color: #eeeeee;
    transition: background-color 100ms ease-in-out; }
