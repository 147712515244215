.blog-edit-dialog {
  min-width: 54em;
  max-width: 54em; }
  .blog-edit-dialog md-dialog-content {
    overflow: hidden; }
  .blog-edit-dialog .blog-edit-dialog__container .ql-container .ql-editor {
    opacity: 0; }

[ng-quill-editor] {
  display: block; }
