.input-field {
  max-width: 100px;
  margin: 8px; }

.input-container {
  margin: 8px; }

.record-card {
  margin: 8px;
  padding: 8px; }

.record-card:hover {
  cursor: pointer;
  background-color: #eeeeee; }

.list-container {
  width: 100% !important; }

.header {
  margin: 0px;
  padding: 0px; }
