.select-root-keyword {
  width: 96%; }
  .select-root-keyword mat-form-field {
    width: 96%;
    margin: 8px; }

.lo-select-container {
  height: 500px;
  overflow: scroll;
  padding: 0 16px; }
  .lo-select-container .lo-select__list__filter {
    width: 100%; }

.demo-agent-button-container {
  margin: 8px; }

.results-container {
  width: 100%; }

#results-copy-button {
  margin: 8px 0px 8px 8px; }
