.ui-grid-vcenter > div {
  padding-top: 0.6em;
  padding-left: 0.8em; }

.ui-grid-row:nth-child(odd):hover .ui-grid-cell {
  background-color: #fdfdfd; }

.ui-grid-row:nth-child(even):hover .ui-grid-cell {
  background-color: #f3f3f3; }

.ui-grid-footer-info {
  color: darkgrey; }

.basic-grid {
  width: 500px;
  height: 500px; }
