.red {
  color: red !important; }

.green {
  color: teal !important; }

.orange {
  color: orange !important; }

.icon {
  margin: 0px; }

.seat-count-chart-dialog {
  width: 70vw; }
