.native-app-form {
  width: 30em;
  padding: 8px; }
  .native-app-form__link {
    cursor: pointer; }
  .native-app-form__content {
    width: 100%;
    padding: 10px; }
    .native-app-form__content mat-form-field {
      margin: 8px 0px;
      width: 100%; }
  .native-app-form__action-buttons {
    width: 100%; }
    .native-app-form__action-buttons button {
      margin: 5px; }
