.container {
  width: 100%;
  max-width: 82vw;
  height: 90vh; }

.grid {
  width: 100%;
  height: 93vh; }

.bold {
  font-weight: bolder; }
