.disclaimer-edit-dialog {
  min-width: 50em;
  max-width: 50em;
  min-height: 43em;
  max-height: 43em; }
  .disclaimer-edit-dialog__content {
    width: 100%;
    padding-right: 0.25em;
    padding-left: 0.25em; }
    .disclaimer-edit-dialog__content__title {
      padding-bottom: 1em; }
      .disclaimer-edit-dialog__content__title span {
        padding-left: 0.7em; }
      .disclaimer-edit-dialog__content__title md-icon {
        color: black; }
    .disclaimer-edit-dialog__content__editor {
      padding-left: 2em;
      width: 100%;
      max-height: 30em !important; }
      .disclaimer-edit-dialog__content__editor .ql-container .ql-editor {
        height: 100%;
        max-height: 22.5em !important;
        overflow-y: scroll; }
      .disclaimer-edit-dialog__content__editor.editor-error .ql-editor {
        border: 1px solid red; }
    .disclaimer-edit-dialog__content__error {
      padding-left: 2.6em;
      min-height: 2em;
      color: #d50000; }
  .disclaimer-edit-dialog .ql-add-variable {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    color: #2196f3;
    font-weight: 500;
    box-sizing: border-box;
    cursor: pointer; }
  .disclaimer-edit-dialog .edit-disclaimer-body {
    min-height: 60vh; }

.disclaimer-variables-dialog {
  max-height: 72vh !important;
  min-height: 60vh !important; }
