.container {
  position: relative;
  height: 100%;
  width: 100%; }

.edit-customer-dialog-component {
  max-height: 80vh !important; }

.app-view-mddialog {
  width: 375px;
  position: relative;
  overflow: hidden !important; }

.confirm-icon {
  margin: 0px 16px 0px 0px !important;
  padding: 0px !important; }
