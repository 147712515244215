.boards__container__table {
  box-sizing: border-box;
  overflow: hidden; }

.edit-board {
  margin: 0px;
  font-family: inherit;
  white-space: inherit;
  height: 100%;
  width: 80vw; }

.edit-board-dialog {
  height: 80vh; }

.no-data {
  padding: 10px;
  color: gray; }

agm-map {
  height: 500px; }
