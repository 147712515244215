ui-view {
  width: 100%; }

.router-body {
  width: 100%;
  min-width: 80%; }

.website {
  height: 100vh;
  width: 100vw; }

.main-content {
  height: calc(100% - 64px);
  /* minus the toolbar min height */
  height: -o-calc(100% - 64px);
  /* opera */
  height: -webkit-calc(100% - 64px);
  /* google, safari */
  height: -moz-calc(100% - 64px);
  /* firefox */ }

.wavy {
  position: relative;
  -webkit-box-reflect: below -10px linear-gradient(transparent, rgba(0, 0, 0, 0.6));
  font-size: 36px;
  margin: 8px 16px; }

.wavy span {
  position: relative;
  display: inline-block;
  animation: animate 1s ease-in-out 0s infinite;
  animation-delay: calc(0.1s * var(--i)); }

@keyframes animate {
  0% {
    transform: translateY(0px); }
  20% {
    transform: translateY(-2px); }
  40%, 100% {
    transform: translateY(0px); } }
