md-icon.icon-size-1 {
  width: 1px !important;
  height: 1px !important; }

md-icon.icon-size-2 {
  width: 2px !important;
  height: 2px !important; }

md-icon.icon-size-3 {
  width: 3px !important;
  height: 3px !important; }

md-icon.icon-size-4 {
  width: 4px !important;
  height: 4px !important; }

md-icon.icon-size-5 {
  width: 5px !important;
  height: 5px !important; }

md-icon.icon-size-6 {
  width: 6px !important;
  height: 6px !important; }

md-icon.icon-size-7 {
  width: 7px !important;
  height: 7px !important; }

md-icon.icon-size-8 {
  width: 8px !important;
  height: 8px !important; }

md-icon.icon-size-9 {
  width: 9px !important;
  height: 9px !important; }

md-icon.icon-size-10 {
  width: 10px !important;
  height: 10px !important; }

md-icon.icon-size-11 {
  width: 11px !important;
  height: 11px !important; }

md-icon.icon-size-12 {
  width: 12px !important;
  height: 12px !important; }

md-icon.icon-size-13 {
  width: 13px !important;
  height: 13px !important; }

md-icon.icon-size-14 {
  width: 14px !important;
  height: 14px !important; }

md-icon.icon-size-15 {
  width: 15px !important;
  height: 15px !important; }

md-icon.icon-size-16 {
  width: 16px !important;
  height: 16px !important; }

md-icon.icon-size-17 {
  width: 17px !important;
  height: 17px !important; }

md-icon.icon-size-18 {
  width: 18px !important;
  height: 18px !important; }

md-icon.icon-size-19 {
  width: 19px !important;
  height: 19px !important; }

md-icon.icon-size-20 {
  width: 20px !important;
  height: 20px !important; }

md-icon.icon-size-21 {
  width: 21px !important;
  height: 21px !important; }

md-icon.icon-size-22 {
  width: 22px !important;
  height: 22px !important; }

md-icon.icon-size-23 {
  width: 23px !important;
  height: 23px !important; }

md-icon.icon-size-24 {
  width: 24px !important;
  height: 24px !important; }

md-icon.icon-size-25 {
  width: 25px !important;
  height: 25px !important; }

md-icon.icon-size-26 {
  width: 26px !important;
  height: 26px !important; }

md-icon.icon-size-27 {
  width: 27px !important;
  height: 27px !important; }

md-icon.icon-size-28 {
  width: 28px !important;
  height: 28px !important; }

md-icon.icon-size-29 {
  width: 29px !important;
  height: 29px !important; }

md-icon.icon-size-30 {
  width: 30px !important;
  height: 30px !important; }

md-icon.icon-size-31 {
  width: 31px !important;
  height: 31px !important; }

md-icon.icon-size-32 {
  width: 32px !important;
  height: 32px !important; }

md-icon.icon-size-33 {
  width: 33px !important;
  height: 33px !important; }

md-icon.icon-size-34 {
  width: 34px !important;
  height: 34px !important; }

md-icon.icon-size-35 {
  width: 35px !important;
  height: 35px !important; }

md-icon.icon-size-36 {
  width: 36px !important;
  height: 36px !important; }

md-icon.icon-size-37 {
  width: 37px !important;
  height: 37px !important; }

md-icon.icon-size-38 {
  width: 38px !important;
  height: 38px !important; }

md-icon.icon-size-39 {
  width: 39px !important;
  height: 39px !important; }

md-icon.icon-size-40 {
  width: 40px !important;
  height: 40px !important; }

md-icon.icon-size-41 {
  width: 41px !important;
  height: 41px !important; }

md-icon.icon-size-42 {
  width: 42px !important;
  height: 42px !important; }

md-icon.icon-size-43 {
  width: 43px !important;
  height: 43px !important; }

md-icon.icon-size-44 {
  width: 44px !important;
  height: 44px !important; }

md-icon.icon-size-45 {
  width: 45px !important;
  height: 45px !important; }

md-icon.icon-size-46 {
  width: 46px !important;
  height: 46px !important; }

md-icon.icon-size-47 {
  width: 47px !important;
  height: 47px !important; }

md-icon.icon-size-48 {
  width: 48px !important;
  height: 48px !important; }

md-icon.icon-size-49 {
  width: 49px !important;
  height: 49px !important; }

md-icon.icon-size-50 {
  width: 50px !important;
  height: 50px !important; }

md-icon.icon-size-51 {
  width: 51px !important;
  height: 51px !important; }

md-icon.icon-size-52 {
  width: 52px !important;
  height: 52px !important; }

md-icon.icon-size-53 {
  width: 53px !important;
  height: 53px !important; }

md-icon.icon-size-54 {
  width: 54px !important;
  height: 54px !important; }

md-icon.icon-size-55 {
  width: 55px !important;
  height: 55px !important; }

md-icon.icon-size-56 {
  width: 56px !important;
  height: 56px !important; }

md-icon.icon-size-57 {
  width: 57px !important;
  height: 57px !important; }

md-icon.icon-size-58 {
  width: 58px !important;
  height: 58px !important; }

md-icon.icon-size-59 {
  width: 59px !important;
  height: 59px !important; }

md-icon.icon-size-60 {
  width: 60px !important;
  height: 60px !important; }

md-icon.icon-size-61 {
  width: 61px !important;
  height: 61px !important; }

md-icon.icon-size-62 {
  width: 62px !important;
  height: 62px !important; }

md-icon.icon-size-63 {
  width: 63px !important;
  height: 63px !important; }

md-icon.icon-size-64 {
  width: 64px !important;
  height: 64px !important; }

.chip {
  border-radius: 0.6em 0.6em 0.6em 0.6em;
  opacity: 0.8;
  padding-right: 0.25em;
  padding-left: 0.55em;
  padding-bottom: 0.25em;
  padding-top: 0.25em;
  color: white; }

.black {
  color: #555555 !important; }
