.saved-chart-card {
  height: 20px;
  width: 90%;
  margin: 8px;
  cursor: pointer; }

.saved-chart-card:hover {
  background-color: #eeeeee; }

.save-button {
  margin: 8px; }

.save-label {
  margin: 8px;
  padding: 8px; }

.delete-icon:hover {
  background-color: lightgray; }
