.lending-officer-select {
  height: 30px;
  min-height: 30px; }

.options:hover {
  background-color: grey; }

.mat-autocomplete-panel {
  min-width: 300px; }

.select-lending-officer-form-field {
  width: 96%; }

.select-lending-officer-form {
  width: 100%; }

.select_lending-officer_autocomplete {
  min-width: 400px !important; }
