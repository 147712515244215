.check-deny-list {
  width: 30em;
  margin: 8px;
  padding: 10px; }
  .check-deny-list mat-form-field {
    margin: 8px 0px;
    width: 100%; }
  .check-deny-list .mat-card {
    width: 100%; }
  .check-deny-list .mat-card > .mat-card-actions:last-child {
    margin: 0;
    padding: 0; }
  .check-deny-list .no-results {
    width: 100%; }
  .check-deny-list .not-found {
    margin: 30px 0px; }
