.customer-import-agent-dialog {
  min-height: 40em;
  max-height: 40em; }

.customer-import-agent-dialog__md-dialog-actions {
  padding-left: 0em; }

.import-agent-body {
  min-height: 68vh; }

.requirements {
  width: 66.6%; }

.progress-body {
  padding: 1em; }
  .progress-body md-tab-body {
    position: relative; }

.progress-body-button {
  position: absolute;
  bottom: 0em;
  right: 0em; }

.import-loader {
  padding: 20px; }
