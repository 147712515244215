.spacer {
  padding: 8px;
  margin: 8px;
  width: 100%; }

.done {
  color: black; }

.editing {
  color: green; }

.keyword-mage-container {
  overflow-y: scroll;
  max-height: 90vh; }

.reset-button {
  width: 20%;
  font-weight: bolder; }

.summary-card {
  width: 50%;
  margin: 8px; }

.summary-content {
  margin: 4px;
  width: 100%; }
