.edit-customer-form-field {
  width: 100%;
  margin: 0px 8px; }

.edit-customer-mat-icon {
  padding: 8px; }

.mat-form-field-prefix {
  top: 0.6em !important; }

.select-customer-mage {
  width: 100% !important; }

.new-customer-header {
  width: 100%; }

.new-customer-select-customer {
  width: 40vw; }
