.edit-agent-form-field {
  width: 100%;
  margin: 0px 8px; }

.edit-agent-mat-icon {
  padding: 8px; }

.mat-form-field-prefix {
  top: 0.6em !important; }

.select-agent-mage {
  width: 100% !important; }

.agent-mage-header {
  width: 100%; }

.agent-mage-select-agent {
  width: 40vw; }
