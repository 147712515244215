.in-menu-button {
  border-width: 0px;
  margin: 0px 16px 0px 0px;
  padding: 0px;
  height: 100%;
  width: 100%;
  text-align: left;
  text-transform: none !important; }

md-button {
  cursor: pointer; }

.confirm {
  margin: 0px !important;
  padding: 0px !important;
  min-width: 50px; }
