.lending-officer-template-body {
  margin: 8px;
  width: 50vw;
  overflow: auto; }
  .lending-officer-template-body mat-form-field {
    width: 100%;
    padding-right: 8px; }
  .lending-officer-template-body color-picker {
    width: 100%; }

.lending-officer-template-text-area {
  width: 100%; }
  .lending-officer-template-text-area .text-area {
    height: 100%; }

.lending-officer-template-image-container img {
  margin: 4px;
  width: 30%; }

.lending-officer-template-image-container choose-image-button {
  margin: 4px;
  width: 30%; }
