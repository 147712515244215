.keyword-mage-form-field {
  width: 100%;
  margin: 0px 8px; }

.keyword-mage-mat-icon {
  padding: 8px; }

.mat-form-field-prefix {
  top: 0.6em !important; }

.select-keyword-mage {
  width: 100% !important; }

.keyword-mage-header {
  width: 100%; }

.keyword-mage-select-keyword {
  width: 40vw; }

.keyword-mage-form-toggle {
  width: 100%;
  margin: auto;
  padding: 10px; }

.keyword-mage-button {
  margin: 8px !important; }
