.keywords-agent-assign-dialog {
  width: 20em; }

.keyword-results-list {
  width: 100%;
  height: 34vh;
  overflow: auto;
  border: #eeeeee 2px solid;
  padding: 8px; }

.keywords-agent-item {
  width: 100%;
  transition: background-color 100ms ease-in-out;
  padding: 8px;
  border-radius: 25px; }
  .keywords-agent-item:hover {
    background-color: #eeeeee;
    transition: background-color 100ms ease-in-out; }
