.customer-removals-dialog {
  height: 100%;
  width: 50%; }

.customer-removals-dialog__body {
  height: 100%; }

.import-loader {
  padding: 20px; }

.tab-body {
  width: 95%; }

.customer-removals_subtitle {
  padding: .5em; }

.customer-removals_copy-results-button {
  position: absolute;
  bottom: 0em;
  right: 0em; }

.customerRemovalStepContainer {
  max-height: 40vh; }
