.omni-search {
  --size: 40px;
  border: 2px solid white;
  display: flex;
  border-radius: 50px;
  height: var(--size);
  width: var(--size);
  color: white;
  padding: 3px;
  position: relative;
  transition: width 300ms ease-in-out;
  overflow: hidden; }
  .omni-search-input {
    flex-grow: 1;
    font-size: 1.5rem;
    padding: 0 0.5em;
    color: white;
    border: 0;
    position: absolute;
    background: transparent;
    opacity: 0;
    top: 0;
    bottom: 0;
    left: 0;
    width: 80%;
    line-height: var(--size);
    cursor: pointer; }
    .omni-search-input:focus {
      outline: 0; }
  .omni-search-submit {
    font-size: 1.5rem;
    cursor: pointer;
    background: transparent;
    border-radius: 50%;
    background: transparent;
    border: 0;
    width: var(--size);
    height: var(--size);
    margin-left: auto;
    transition: background 150ms ease-in-out; }
  .omni-search:focus-within {
    width: 300px; }
    .omni-search:focus-within .omni-search-input {
      opacity: 1;
      cursor: initial; }
    .omni-search:focus-within .omni-search-submit {
      background: orange;
      color: white; }
      .omni-search:focus-within .omni-search-submit:focus, .omni-search:focus-within .omni-search-submit:hover {
        outline: 0;
        box-shadow: 0 0 10px grey; }
  .omni-search ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1;
    /* Firefox */ }
  .omni-search :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: white; }
  .omni-search ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: white; }

.omni-search-icon {
  color: white; }
