.omni-search-progress svg {
  width: 30vw !important;
  height: 30vh !important; }

.omni-search-loading {
  font-size: 32px;
  color: grey;
  z-index: 1; }

.find-result {
  margin: 8px; }

.find-result-select {
  transition: all 300ms ease-in-out; }

.find-result-select:hover {
  background-color: #cccccc;
  border-radius: 10px;
  cursor: pointer; }

.find-result-select:active {
  border-radius: 0px;
  transition: all 100ms ease-in-out; }
