.main {
  height: 100vh !important;
  width: 100%; }

.login-mat-card {
  width: 20em;
  padding: 0px !important; }

.login-mat-card-content {
  margin: 32px; }
