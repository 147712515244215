.agent-vett-mls__content {
  margin: 8px;
  max-height: 72vh;
  max-width: 32em;
  min-width: 24em;
  padding: 8px;
  overflow: scroll; }
  .agent-vett-mls__content__form {
    overflow: auto; }
    .agent-vett-mls__content__form mat-form-field {
      margin: 8px 0px;
      width: 100%; }
  .agent-vett-mls__content__list__card {
    margin: 8px 0px; }
  .agent-vett-mls__content__list__filter {
    width: 96%;
    position: sticky !important;
    top: -8px;
    background-color: white;
    z-index: 1;
    padding: 0px 8px; }

.agent-vett-mls__action-buttons {
  width: 100%; }
  .agent-vett-mls__action-buttons button {
    margin: 8px; }
