.content {
  min-width: 90vw;
  min-height: 90vh;
  max-height: 90vh; }
  .content .activity {
    height: 100%; }
  .content .report {
    height: 100%; }
  .content md-tab-content {
    overflow: hidden; }

.fusebill {
  height: 28px; }

svg {
  fill: black !important; }

#offset-checkbox {
  margin-left: 45px; }

.flyer-templates .mat-expansion-panel-body {
  height: 33vh; }
  .flyer-templates .mat-expansion-panel-body img {
    width: 100%;
    height: 100%;
    object-fit: contain; }
