:root {
  --text-primary: black;
  --text-secondary: #ececec;
  --icon-accent: #009688;
  --bg-primary: #009688;
  --bg-secondary: white;
  --transition-speed: 600ms; }

.navbar {
  position: fixed;
  background-color: var(--bg-primary);
  transition: width 600ms ease;
  overflow: scroll; }

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  height: 100%; }

.nav-item {
  width: 100%; }

.nav-item:last-child {
  margin-top: auto; }

.nav-link {
  display: flex;
  align-items: center;
  height: 1rem;
  color: var(--icon-accent);
  text-decoration: none;
  filter: grayscale(100%);
  transition: var(--transition-speed); }

.nav-link:hover {
  filter: grayscale(0%) opacity(1);
  background: var(--bg-primary);
  color: var(--bg-secondary); }

.icon {
  margin: 0px 8px 0px 0px !important; }

.mmi-icon {
  margin: 8px 8px 0px 0px !important; }

.text-link:hover {
  color: var(--text-secondary); }

.link-text {
  display: none;
  white-space: nowrap;
  margin-left: 1rem; }

.nav-link svg {
  width: 1.5rem;
  min-width: 1.5rem;
  margin: 0 .5rem 0 0; }

/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    top: 0;
    width: 5rem;
    height: 100vh; }
  .navbar:hover {
    width: 16rem; }
  .navbar:hover .link-text {
    display: block;
    white-space: nowrap; }
  .link-text:hover {
    color: var(--text-secondary) !important; }
  .navbar:hover .logo svg {
    margin-left: 11rem; }
  .navbar:hover .logo-text {
    left: 0px; } }

.fa-spin {
  -webkit-animation: fa-spin 2s 1 linear;
  animation: fa-spin 2s 1 linear; }

.fa-pulse {
  -webkit-animation: fa-spin 1s infinite steps(8);
  animation: fa-spin 1s infinite steps(8); }

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }
