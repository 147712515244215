@charset "UTF-8";
.loader {
  width: 250px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  font-family: helvetica, arial, sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: white;
  letter-spacing: 0.2em; }
  .loader::before, .loader::after {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: white;
    position: absolute;
    animation: load .7s infinite alternate ease-in-out; }
  .loader::before {
    top: 0; }
  .loader::after {
    bottom: 0; }

@keyframes load {
  0% {
    left: 0;
    height: 30px;
    width: 15px; }
  50% {
    height: 8px;
    width: 40px; }
  100% {
    left: 235px;
    height: 30px;
    width: 15px; } }

:root {
  --duration: 1.5s;
  --container-size: 100px;
  --box-size: 15px;
  --box-border-radius: 15%; }

html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center; }

.box-loader-container {
  width: var(--container-size);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; }

.📦 {
  width: var(--box-size);
  height: var(--box-size);
  position: relative;
  display: block;
  transform-origin: -50% center;
  border-radius: var(--box-border-radius); }
  .📦:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: lightblue;
    border-radius: var(--box-border-radius);
    box-shadow: 0px 0px 10px 0px rgba(251, 140, 0, 0.4); }
  .📦:nth-child(1) {
    animation: slide var(--duration) ease-in-out infinite alternate; }
    .📦:nth-child(1):after {
      animation: color-change var(--duration) ease-in-out infinite alternate; }
  .📦:nth-child(2) {
    animation: flip-1 var(--duration) ease-in-out infinite alternate; }
    .📦:nth-child(2):after {
      animation: squidge-1 var(--duration) ease-in-out infinite alternate; }
  .📦:nth-child(3) {
    animation: flip-2 var(--duration) ease-in-out infinite alternate; }
    .📦:nth-child(3):after {
      animation: squidge-2 var(--duration) ease-in-out infinite alternate; }
  .📦:nth-child(4) {
    animation: flip-3 var(--duration) ease-in-out infinite alternate; }
    .📦:nth-child(4):after {
      animation: squidge-3 var(--duration) ease-in-out infinite alternate; }
  .📦:nth-child(5) {
    animation: flip-4 var(--duration) ease-in-out infinite alternate; }
    .📦:nth-child(5):after {
      animation: squidge-4 var(--duration) ease-in-out infinite alternate; }
  .📦:nth-child(2):after {
    background-color: #fb8c00; }
  .📦:nth-child(3):after {
    background-color: #ff9800; }
  .📦:nth-child(4):after {
    background-color: #ffa726; }
  .📦:nth-child(5):after {
    background-color: #ffb74d; }

@keyframes slide {
  0% {
    background-color: #ef6c00;
    transform: translatex(0vw); }
  100% {
    background-color: #ffb74d;
    transform: translatex(calc(var(--container-size) - (var(--box-size) * 1.25))); } }

@keyframes color-change {
  0% {
    background-color: #ef6c00; }
  100% {
    background-color: #ffb74d; } }

@keyframes flip-1 {
  0%, 15% {
    transform: rotate(0); }
  35%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-1 {
  5% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  15% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  25%, 20% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  55%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  40% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-2 {
  0%, 30% {
    transform: rotate(0); }
  50%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-2 {
  20% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  30% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  40%, 35% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  70%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  55% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-3 {
  0%, 45% {
    transform: rotate(0); }
  65%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-3 {
  35% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  45% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  55%, 50% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  85%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  70% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }

@keyframes flip-4 {
  0%, 60% {
    transform: rotate(0); }
  80%, 100% {
    transform: rotate(-180deg); } }

@keyframes squidge-4 {
  50% {
    transform-origin: center bottom;
    transform: scalex(1) scaley(1); }
  60% {
    transform-origin: center bottom;
    transform: scalex(1.3) scaley(0.7); }
  70%, 65% {
    transform-origin: center bottom;
    transform: scalex(0.8) scaley(1.4); }
  100%, 100% {
    transform-origin: center top;
    transform: scalex(1) scaley(1); }
  85% {
    transform-origin: center top;
    transform: scalex(1.3) scaley(0.7); } }
