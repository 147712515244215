.customer-import-lending-officer-dialog {
  min-height: 40em;
  max-height: 40em; }

.customer-import-lending-officer-dialog__md-dialog-actions {
  padding-left: 0em; }

.import-lending-officer-body {
  min-height: 68vh; }

.requirements {
  width: 100%; }

.lo-progress-body {
  padding: 1em; }
  .lo-progress-body md-tab-body {
    position: relative; }

.lo-progress-body-button {
  position: absolute;
  bottom: 0em;
  right: 0em; }

.horizontal-container {
  width: 100%; }

.import-loader {
  padding: 20px; }

.import-lending-officer-button-container {
  padding: 0px 8px 8px 8px; }
  .import-lending-officer-button-container button {
    width: 100%; }

.importLendingOfficerStepContainer {
  height: 51vh; }
