html,
body {
  overflow-x: hidden !important; }
  html ::-webkit-scrollbar:horizontal,
  body ::-webkit-scrollbar:horizontal {
    display: none !important;
    background-color: transparent !important;
    width: 0px !important; }

#view-container,
md-dialog-content,
md-tab-content,
[md-tabs-template],
md-content,
md-virtual-repeat-container,
.ui-grid-render-container-body .ui-grid-viewport {
  overflow-x: hidden !important; }
  #view-container ::-webkit-scrollbar:horizontal,
  md-dialog-content ::-webkit-scrollbar:horizontal,
  md-tab-content ::-webkit-scrollbar:horizontal,
  [md-tabs-template] ::-webkit-scrollbar:horizontal,
  md-content ::-webkit-scrollbar:horizontal,
  md-virtual-repeat-container ::-webkit-scrollbar:horizontal,
  .ui-grid-render-container-body .ui-grid-viewport ::-webkit-scrollbar:horizontal {
    display: none !important;
    background-color: transparent !important;
    width: 0px !important; }

md-dialog {
  overflow-x: hidden !important;
  overflow-y: hidden !important; }
  md-dialog ::-webkit-scrollbar {
    display: none !important;
    background-color: transparent !important;
    width: 0px !important; }

#view-container,
md-dialog,
md-dialog-content,
md-content,
md-sidenav,
md-virtual-repeat-container,
.md-virtual-repeat-scroller,
.ui-grid-render-container-body .ui-grid-viewport {
  overflow-y: auto !important; }
  #view-container ::-webkit-scrollbar,
  md-dialog ::-webkit-scrollbar,
  md-dialog-content ::-webkit-scrollbar,
  md-content ::-webkit-scrollbar,
  md-sidenav ::-webkit-scrollbar,
  md-virtual-repeat-container ::-webkit-scrollbar,
  .md-virtual-repeat-scroller ::-webkit-scrollbar,
  .ui-grid-render-container-body .ui-grid-viewport ::-webkit-scrollbar {
    display: block !important;
    background-color: inherit !important;
    width: 13px !important; }
  #view-container ::-webkit-scrollbar-thumb,
  md-dialog ::-webkit-scrollbar-thumb,
  md-dialog-content ::-webkit-scrollbar-thumb,
  md-content ::-webkit-scrollbar-thumb,
  md-sidenav ::-webkit-scrollbar-thumb,
  md-virtual-repeat-container ::-webkit-scrollbar-thumb,
  .md-virtual-repeat-scroller ::-webkit-scrollbar-thumb,
  .ui-grid-render-container-body .ui-grid-viewport ::-webkit-scrollbar-thumb {
    background-color: #babac0 !important;
    border-radius: 16px !important;
    border: 4px solid #fbfbfb !important; }
  #view-container ::-webkit-scrollbar-thumb:hover,
  md-dialog ::-webkit-scrollbar-thumb:hover,
  md-dialog-content ::-webkit-scrollbar-thumb:hover,
  md-content ::-webkit-scrollbar-thumb:hover,
  md-sidenav ::-webkit-scrollbar-thumb:hover,
  md-virtual-repeat-container ::-webkit-scrollbar-thumb:hover,
  .md-virtual-repeat-scroller ::-webkit-scrollbar-thumb:hover,
  .ui-grid-render-container-body .ui-grid-viewport ::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5 !important;
    border: 3px solid #f4f4f4 !important; }
  #view-container ::-webkit-scrollbar-track,
  md-dialog ::-webkit-scrollbar-track,
  md-dialog-content ::-webkit-scrollbar-track,
  md-content ::-webkit-scrollbar-track,
  md-sidenav ::-webkit-scrollbar-track,
  md-virtual-repeat-container ::-webkit-scrollbar-track,
  .md-virtual-repeat-scroller ::-webkit-scrollbar-track,
  .ui-grid-render-container-body .ui-grid-viewport ::-webkit-scrollbar-track {
    background-color: inherit !important; }
  #view-container ::-webkit-scrollbar-track:hover,
  md-dialog ::-webkit-scrollbar-track:hover,
  md-dialog-content ::-webkit-scrollbar-track:hover,
  md-content ::-webkit-scrollbar-track:hover,
  md-sidenav ::-webkit-scrollbar-track:hover,
  md-virtual-repeat-container ::-webkit-scrollbar-track:hover,
  .md-virtual-repeat-scroller ::-webkit-scrollbar-track:hover,
  .ui-grid-render-container-body .ui-grid-viewport ::-webkit-scrollbar-track:hover {
    background-color: #f4f4f4 !important;
    border-radius: 16px !important; }
  #view-container ::-webkit-scrollbar-button,
  md-dialog ::-webkit-scrollbar-button,
  md-dialog-content ::-webkit-scrollbar-button,
  md-content ::-webkit-scrollbar-button,
  md-sidenav ::-webkit-scrollbar-button,
  md-virtual-repeat-container ::-webkit-scrollbar-button,
  .md-virtual-repeat-scroller ::-webkit-scrollbar-button,
  .ui-grid-render-container-body .ui-grid-viewport ::-webkit-scrollbar-button {
    display: none; }
