.customer-view-tab-group {
  height: 100%; }
  .customer-view-tab-group .mat-tab-body-wrapper {
    height: 100%; }

.customer-view-form .section {
  width: 100%; }

.customer-view-form .customer-view-input {
  width: 100%;
  margin-right: 8px; }

.customer-view-form mat-form-field {
  width: 100%;
  margin-right: 8px; }

.customer-view-form mat-slide-toggle {
  width: 100%;
  margin: 8px; }

.customer-view-accordian .mat-expansion-panel-body {
  overflow: hidden; }
