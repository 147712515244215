.picture {
  width: 100px;
  height: 100px; }

.listing-card {
  padding: 8px !important;
  cursor: pointer;
  transition: background-color 100ms ease-in-out; }
  .listing-card div:nth-child(n + 1) {
    padding: 8px; }
  .listing-card:hover {
    background-color: #eeeeee;
    transition: background-color 100ms ease-in-out; }

.listing-dialog {
  position: relative;
  -webkit-overflow-scrolling: touch;
  min-width: 32em;
  height: 50em; }
  .listing-dialog .location .text {
    margin-left: 0.4em; }

#horizontal-container {
  margin-top: 8px;
  max-height: 40vh;
  overflow: auto; }
