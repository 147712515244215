.edit-lending-officer__form {
  width: 30em;
  padding: 16px;
  height: 80vh;
  overflow: auto; }
  .edit-lending-officer__form mat-form-field {
    margin: 8px 0px;
    width: 100%; }
  .edit-lending-officer__form h4 {
    margin-bottom: 0px; }

.edit-lending-officer__actions {
  padding: 8px; }
  .edit-lending-officer__actions button {
    margin: 5px; }

.edit-lending-officer-body {
  min-height: 64vh !important; }

.mmi-checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  align-content: stretch; }
