.s > md-card {
  margin: 0em; }

.s__items {
  margin-right: 0em;
  margin-left: 0em;
  padding-top: 0.4em; }
  .s__items__item {
    padding: 0.8em; }
    .s__items__item md-radio-button {
      margin-bottom: 0em; }
